import React from "react"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter, faSnapchat } from '@fortawesome/free-brands-svg-icons';
import mastertoysLogo from '../Images/mt-logo.png';

function Footer(props) {
    const { handleAboutUsClick, handleContactUsClick } = props;

    const [spinner, setSpinner] = React.useState(false);
    const [postSuccess, setPostSuccess] = React.useState(false);
    const [formErrors, setFormErrors] = React.useState({});
    const [submitting, setSubmitting] = React.useState(false);
    const [formData, setFormData] = React.useState(
        {
            firstName: "",
            lastName: "",
            email: "",
            inquiry: ""
        }
    )

    function handleChange(event) {
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                [event.target.name]: event.target.value
            }
        })
    }

    function validateForm(formData) {
        let errors = {};
        if(formData.firstName.length < 1) {
            errors.firstName = "First name required";
        }
        if(formData.lastName.length < 1) {
            errors.lastName = "Last name required";
        }
        if(formData.email.length < 1) {
            errors.email = "Email required";
        }
        if(formData.inquiry.length < 1) {
            errors.inquiry = "Inquiry required";
        }
        
        return errors;
    }

    function handleSubmit(event){
        event.preventDefault();
        setSpinner(true);
        setFormErrors(validateForm(formData));
        setSubmitting(true);
    }

    async function submitContact() {

        // const response = await fetch('http://13.57.199.89:3000/contactus/', {
        // above address for dev server post request also works for localhost without changing the address before /contactus

        // testing if full address is needed
        const response = await fetch('/contactus', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                inquiry: formData.inquiry
            })
        })
        const data = await response.json();
        if(response.ok) {
            console.log(data);
            console.log('success!');
            setPostSuccess(true);
            setSpinner(false);
        }
    }

    React.useEffect(() => {
        if(Object.keys(formErrors).length === 0 && submitting) {
            submitContact();
        } else {
            setPostSuccess(false);
            setSpinner(false);
        }
    }, [formErrors]);

    return(
        <footer className="footer-container footer-inverse-background footer-section-background">
            <div id="footer-contactUsForm" className="footer-sections">
                <h3 className="footer-titles">Lets get in Touch!</h3>
                <form className="form-container">
                    <input type="text" name="firstName" placeholder="First Name*" onChange={handleChange} value={formData.firstName} required aria-label="first name" aria-required="true"/>
                    { formErrors.firstName ? <div style={{ color: "red"}}>*{formErrors.firstName}</div> : "" }
                    <input type="text" name="lastName" placeholder="Last Name*" onChange={handleChange} value={formData.lastName} required aria-label="first name" aria-required="true"/>
                    { formErrors.lastName ? <div style={{ color: "red"}}>*{formErrors.lastName}</div> : "" }
                    <input type="text" name="email" placeholder="Email*" onChange={handleChange} value={formData.email} required aria-label="first name" aria-required="true"/>
                    { formErrors.email? <div style={{ color: "red"}}>*{formErrors.email}</div> : "" }
                    <textarea name="inquiry" maxLength="1024" placeholder="What can we help with?" onChange={handleChange} value={formData.inquiry} required aria-label="first name" aria-required="true"/>
                    { formErrors.inquiry ? <div style={{ color: "red"}}>*{formErrors.inquiry}</div> : "" }
                    { postSuccess ? <div>Inquiry submitted!</div> : "" }
                    <button className={`footer-submit-button ${ spinner ? "button--loading" : ""}`} type="submit" onClick={handleSubmit}><span className="button--text">Submit</span></button>
                </form>
            </div>
            <div className="footer-sections">
                <h3 className="footer-titles">Master Toys Inc</h3>
                <ul className="sitemap-container">
                    <li><a href="https://www.mastertoysinc.com">Home</a></li>
                    <li><a onClick={handleAboutUsClick} role="button">About Us</a></li>
                    <li><a onClick={handleContactUsClick} role="button">Contact Us</a></li>
                    <li><a href="https://mastertoys.markettime.com/">Shop B2B</a></li>
                    <li><a href="https://www.amazon.com/s?me=A2ZWRGQHD20QRC&marketplaceID=ATVPDKIKX0DER">Shop Retail</a></li>
                    <li><a href="https://mastertoys.markettime.com/login">Login</a></li>
                    <li><a href="https://mastertoys.markettime.com/signup">Register</a></li>
                </ul>
            </div>
            <div className="footer-sections footer-social-container">
                <img className="small-mastertoys-logo"src={mastertoysLogo} alt="mastertoy's logo"/>
                <span>
                    <FontAwesomeIcon className="fa-margin-right" icon={faPhone} size="lg"/>
                    (323) 582-8882
                </span>
                <span>
                    <FontAwesomeIcon className="fa-margin-right" icon={faEnvelope} size="lg"/>
                    <a href="mailto:info@mastertoysinc.com">info@mastertoysinc.com</a>
                </span>
                <span>
                    <FontAwesomeIcon className="fa-margin-right" icon={faFacebook} size="2xl"/>
                    <FontAwesomeIcon className="fa-margin-right" icon={faInstagram} size="2xl"/>
                    <FontAwesomeIcon className="fa-margin-right" icon={faTwitter} size="2xl"/>
                    <FontAwesomeIcon className="fa-margin-right" icon={faSnapchat} size="2xl"/>
                </span>
            </div>
        </footer>
    )
}

export default Footer;