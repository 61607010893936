import React from 'react';
import mastertoysLogo from '../Images/mt-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faBars } from '@fortawesome/free-solid-svg-icons';

function Nav(props) {
    const { toggleHamburger, handleAboutUsClick, handleContactUsClick } = props;
    const [ariaExpanded, setAriaExpanded] = React.useState(false);

    function handleMouseEnter() {
        setAriaExpanded(true);
    }

    function handleMouseLeave() {
        setAriaExpanded(false);
    }

    return(
        <>
            <nav className="nav-container">
                <img className="mastertoys-logo" src={mastertoysLogo} alt="mastertoy's logo"/>
                <div className="nav-menu-container">
                    <div className="nav-links-container">
                        <button href="http://13.57.199.38:3000">Home</button>
                        <button onClick={handleAboutUsClick}>About Us</button>
                        <button onClick={handleContactUsClick}>Contact Us</button>
                        <div className=" nav-shopnow-dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                            <button className="shopnow-button" aria-haspopup="true" aria-expanded={ariaExpanded} >Shop Now</button>
                            <div className="nav-shopnow-dropdown-content">
                                <a href="https://www.amazon.com/s?me=A2ZWRGQHD20QRC&marketplaceID=ATVPDKIKX0DER">Retail</a>
                                <a href="https://mastertoys.markettime.com/">B2B</a>
                            </div>
                        </div>
                    </div>
                    <div className="nav-login-container">
                        <button><a href="https://mastertoys.markettime.com/login"><FontAwesomeIcon icon={faUser} size="lg"/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Login</a></button>
                        <button className="nav-register-button"><a href="https://mastertoys.markettime.com/signup">Register</a></button>
                    </div>
                    <div className="nav-hamburger-icon" onClick={toggleHamburger}><FontAwesomeIcon icon={faBars} size="xl"/></div>
                </div>
            </nav>
        </>
    )
}

export default Nav;