import React from "react";
import smokoImage from "../Images/smoko-slide.jpeg";

const imagesArr = [
    smokoImage,
    "https://s3.us-east-2.amazonaws.com/markettime-prod-events-landing-page/R2689/slider-images/bg-image-718_en-us.png",
    "https://s3.us-east-2.amazonaws.com/markettime-prod-events-landing-page/R2689/slider-images/bg-image-716_en-us.png",
];
const slideshowDelay = 2500;

function Slideshow() {
    const [index, setIndex] = React.useState(0);
    const timeoutRef = React.useRef(null);

    function resetTimeout() {
        if(timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    React.useEffect(() => {
        resetTimeout();
        timeoutRef.current = setTimeout(
          () =>
            setIndex((prevIndex) =>
              prevIndex === imagesArr.length - 1 ? 0 : prevIndex + 1
            ),
            slideshowDelay
        );
    
        return () => {
            resetTimeout();
        };
      }, [index]);

    const images = imagesArr.map((backgroundImage, index) => {
        return (
            <div className="slide" key={index} style={{ backgroundImage: `url(${backgroundImage})` }} role="img" aria-label="product showcase slideshow"/>
        )
    })

    const dots = imagesArr.map((_, idx) => {
        return (
            <button
                key={idx}
                className={`slideshow-dot${index === idx ? " active" : ""}`}
                onClick={()=>setIndex(idx)}
                aria-label="slides icon"
            />
        )
    })

    return (
        <>
            <div className="slideshow">
                <div className="slideshowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)`}}>
                    {images}
                </div>
                {/* <button className="slideshow-shopnow-button">Shop Now</button> */}
            </div>
            <div className="slideshow-dots">
                {dots}
            </div>
        </>
    );
}

export default Slideshow;