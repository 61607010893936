import React from 'react';
import './reset.css';
import './style.css';
import Nav from './Components/Nav'
import Slideshow from './Components/Slideshow'
import Main from './Components/Main'
import Footer from './Components/Footer'
import Hamburger from './Components/Hamburger';

function App() {
    const [hamburgerOpen, setHamburgerOpen] = React.useState(false);

    function toggleHamburger() {
        setHamburgerOpen(prevState => !prevState)
    }

    function handleContactUsClick() {
        const element=document.getElementById("footer-contactUsForm");
        element.scrollIntoView({ behavior: 'smooth'});
    }

    function handleAboutUsClick() {
        const element=document.getElementById("main-aboutUs");
        element.scrollIntoView({ behavior: 'smooth'});
    }

    return (
            <body>
                <a className="skip-to-content-link" href="#main-aboutUs">Skip to content</a>
                <Hamburger
                    hamburgerOpen={hamburgerOpen}
                    toggleHamburger={toggleHamburger}
                    handleAboutUsClick={handleAboutUsClick}
                    handleContactUsClick={handleContactUsClick}
                />
                <div className="header-footer-design-element"></div>
                <div className="body-container">
                    <Nav
                        toggleHamburger={toggleHamburger}
                        handleAboutUsClick={handleAboutUsClick}
                        handleContactUsClick={handleContactUsClick}
                    />
                    <Slideshow />
                    <Main />
                    <Footer
                        handleAboutUsClick={handleAboutUsClick}
                        handleContactUsClick={handleContactUsClick}
                    />
                </div>
                <div className="header-footer-design-element">
                    © 2024 Master Toys Incorporated. All Rights Reserved.
                </div>
            </body>
    );
}

export default App;