function Hamburger(props) {
    const { hamburgerOpen, toggleHamburger, handleAboutUsClick, handleContactUsClick } = props;

    function contactUsClick() {
        toggleHamburger();
        handleContactUsClick();
    }

    function aboutUsClick() {
        toggleHamburger();
        handleAboutUsClick();
    }

    return(
        <>
            <div className="hamburger-container" style={{ display: `${ hamburgerOpen ? 'inline' : 'none'}`}}>
                <button className="close-hamburger-button" onClick={toggleHamburger}>X</button>
                <div className="hamburger-list">
                    <a onClick={aboutUsClick}>About Us</a>
                    <a onClick={contactUsClick}>Contact Us</a>
                    <a href="https://www.amazon.com/s?me=A2ZWRGQHD20QRC&marketplaceID=ATVPDKIKX0DER">Shop Retail</a>
                    <a href="https://mastertoys.markettime.com/">Shop B2B</a>
                    <a href="https://mastertoys.markettime.com/login">Login</a>
                    <a href="https://mastertoys.markettime.com/signup">Register</a>
                </div>
            </div>
        </>
    )
}

export default Hamburger;