import React from 'react';
import pacman from '../Images/pacman.png'
import pencilNote from '../Images/pencil-note.png'
import socials from '../Images/socials.png'
import jsonp from 'jsonp';

function Main() {
    const [spinner, setSpinner] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [newsletterMsg, setNewsletterMsg] = React.useState("");
    const [ariaExpanded, setAriaExpanded] = React.useState(false);

    function handleMouseEnter() {
        setAriaExpanded(true);
    }

    function handleMouseLeave() {
        setAriaExpanded(false);
    }

    function handleChange(event) {
        setEmail(event.target.value)
    }

    function submitNewsletter(event) {
        event.preventDefault();
        setSpinner(true);
        const url = 'https://mastertoysinc.us2.list-manage.com/subscribe/post-json?u=641eb9582aa60ca5c23581f17&amp;id=ce519d1496&amp;f_id=000076e0f0';
        jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
            const { msg, result } = data;
            setNewsletterMsg(msg);
            setSpinner(false);
        })
    }

    return(
        <main className="main-container">
            <div id="main-aboutUs" className="main-sections">
                <div className="main-section-columns">
                    <h1>Master Toys & Novelties</h1>
                    <br></br>
                    <p>Master Toys and Novelties, Inc. is located in Los Angeles and has been a manufacturer, direct importer, and wholesaler of toys, dolls, novelty gift items, and die-cast metal cars for over 30 years.</p>
                    <br></br>
                    <div className="main-shopnow-dropdown-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        <button className="main-section-buttons shopnow-button" aria-haspopup="true" aria-expanded={ariaExpanded}>Shop Now</button>
                        <div className="main-shopnow-dropdown-content">
                            <a href="https://www.amazon.com/s?me=A2ZWRGQHD20QRC&marketplaceID=ATVPDKIKX0DER">Retail</a>
                            <a href="https://mastertoys.markettime.com/">B2B</a>
                        </div>
                    </div>
                </div>
                <div className="main-section-columns">
                    <img className="main-section-images" src={pacman} alt="image of pacman plushies"/>
                </div>
            </div>
            <div className="main-sections main-middle-section-background main-inverse-background">
                <div className="main-section-columns">
                    <img className="main-section-images" src={pencilNote} alt="image of paper and pen"/>
                </div>
                <div className="main-section-columns">
                    <h2>Coming soon...</h2>
                    {/* <h2>Support</h2> */}
                    <br></br>
                    <p>Got questions? Need help? Checkout our self-help portal or get connected with a representative.</p>
                    <br></br>
                    <div className="main-section-buttons coming-soon">Self-help Portal</div>
                </div>
            </div>
            <div className="main-sections">
                <div className="main-section-columns">
                    <h2>Follow Us!</h2>
                    <br></br>
                    <p>Follow our socials and be the first to find out exciting
                    and new products and promotions!</p>
                    <br></br>
                    <form>
                        <input className="main-section-form-input" type="email" name="EMAIL" placeholder="Email*" required aria-label="email" aria-required="true" onChange={handleChange} value={email}></input>
                        <br></br>
                        <br></br>
                        <p>{newsletterMsg}</p>
                        {newsletterMsg ? <br></br> : ""}
                        <button className={`main-section-buttons ${ spinner ? "button--loading" : ""}`} type="submit" onClick={submitNewsletter}><span className="button--text">Join our Newsletter</span></button>
                    </form>
                </div>
                <div className="main-section-columns">
                    <img className="main-section-images" src={socials} alt="image of phone and social media logos"/>
                </div>
            </div>
        </main>
    )
}

export default Main;